import React, { useState } from "react";
import Navbar from "./inc/Navbar";
import Footer from "./inc/Footer";
import { Link } from "react-router-dom";

const Allproduct = () => {
  const [trPrList, setTrPrList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [mainId, setMainId] = useState("");

  const product = async () => {
    let trprres = await fetch(window.api + "allProducts");
    trprres = await trprres.json();
    setTrPrList(trprres.products);
    setCategoryList(trprres.category);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    product();
  }, []);

  async function getProduct(idValue) {
    setMainId(idValue);
    let proRes = await fetch(window.api + "getProducts/" + idValue);
    proRes = await proRes.json();
    setTrPrList(proRes.products);
  }

  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-9">
            <div className="row">
              <div className="col">
                <center>
                  <h3>All Products</h3>
                </center>
              </div>
            </div>
            <div className="row mt-1 grid">
              {Object.keys(trPrList).length > 0 ? (
                trPrList.map((v) => (
                  <div className="col-md-4">
                    <Link to={"/Product/" + v.id}>
                      <div className="product">
                        <figure className="product-media">
                          <img
                            src={window.storage + v.image}
                            alt="Product image"
                            className="product-image"
                          />
                          <img
                            src={window.storage + v.image}
                            alt="Product image"
                            className="product-image-hover"
                          />
                        </figure>
                        <div className="product-body">
                          <h3 className="product-title">{v.name}</h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="sideWidget cat">
              <h1 className="sideWidget-title">Categories</h1>
              <ul>
                {Object.keys(categoryList).length > 0 ? (
                  categoryList.map((c) =>
                    c.id == mainId ? (
                      <>
                        <li
                          style={{ backgroundColor: "#4F97E6", color: "white" }}
                          onClick={() => getProduct(c.id)}
                        >
                          {c.name}
                        </li>
                      </>
                    ) : (
                      <>
                        <li onClick={() => getProduct(c.id)}>{c.name}</li>
                      </>
                    )
                  )
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Allproduct;
