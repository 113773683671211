import React, { useState } from 'react'
import Navbar from './inc/Navbar'
import Footer from './inc/Footer'
import contact from "./images/contact-header-bg.jpg"

const Contact = () => {
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")
	const [subject, setSubject] = useState("")
	const [msg, setMsg] = useState("")
	const [success, setSuccess] = useState(false)

	async function addMsg() {
		// setloader(true)

		const formData = new FormData()

		formData.append('name', name);
		formData.append('email', email);
		formData.append('phone', phone);
		formData.append('subject', subject);
		formData.append('msg', msg);

		let result = await fetch(window.api + "addContact", {
			method: 'POST',
			body: formData

		});
		result = await result.json();
		if (result.success) {
			setSuccess(true);
			// setloader(false);
			setName('')
			setEmail('')
			setPhone('')
			setSubject('')
			setMsg('')
			
		}
	}

	return (
		<>
			<div className="page-wrapper">
				<Navbar />
				<main className="main">
					<br />
					<div className="container">
						<div className="page-header page-header-big text-center" style={{ backgroundImage: `url(${contact})` }}>
							<h1 className="page-title text-white">Contact us<span className="text-white">keep in touch with us</span></h1>
						</div>
					</div>
					<div className="page-content">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 mb-2 mb-lg-0">
									<h2 className="title mb-1">Contact Information</h2>
									<p className="mb-3">Dawn Analytical Supplies & Calibrators. For urgency kindly contact: <a href="mailto:#">dawnanalyticals@gmail.com</a></p>
									<div className="row">
										<div className="col-sm-6">
											<div className="contact-info">
												<h3>Office Address & Mobile</h3>

												<ul className="contact-list">
													<li>
														<i className="icon-map-marker"></i>
														75 M, M Block, Model Town Ext, Lahore-Pakistan
													</li>
													<li>
														<i className="icon-phone"></i>
														+92 42 35171076
													</li>
													<li>
														<i className="icon-phone"></i>
														+92 334 9814152
													</li>
													<li>
														<i className="icon-phone"></i>
														+92 42 35171075
													</li>
												</ul>
											</div>
										</div>

										<div className="col-sm-6">
											<div className="contact-info">
												<h3>Office Emails</h3>

												<ul className="contact-list">
												    <li>
														<i className="icon-envelope"></i>
														<a href="mailto:#">info@dawnanalyticals.com</a><br />
														
													</li>
													<li>
														<i className="icon-envelope"></i>
														<a href="mailto:#">sales@dawnanalyticals.com</a>
													</li>
													<li>
														<i className="icon-envelope"></i>
														<a href="mailto:#">service@dawnanalyticals.com</a>
													</li>
													<li>
														<i className="icon-envelope"></i>
														<a href="mailto:#">dawnanalyticals@gmail.com</a>
													</li>
													{/* <li>
														<i className="icon-clock-o"></i>
														<span className="text-dark">Monday-Saturday</span> <br />11am-7pm ET
													</li>
													<li>
														<i className="icon-calendar"></i>
														<span className="text-dark">Sunday</span> <br />11am-6pm ET
													</li> */}
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-6">
									<h2 className="title mb-1">Got Any Questions?</h2>
									<p className="mb-2">Use the form below to Enquiry, Services and Contact.</p>
									{success
										? <div className="alert alert-success" role="alert"> Massage sent Successfully!</div>
										: <div></div>
									}
									<br />
									<div className="contact-form mb-3">
										<div className="row">
											<div className="col-sm-6">
												<label for="cname" className="sr-only">Name</label>
												<input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" id="cname" placeholder="Name *" required />
											</div>

											<div className="col-sm-6">
												<label for="cemail" className="sr-only">Email</label>
												<input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="cemail" placeholder="Email *" required />
											</div>
										</div>

										<div className="row">
											<div className="col-sm-6">
												<label for="cphone" className="sr-only">Phone</label>
												<input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" id="cphone" placeholder="Phone" />
											</div>

											<div className="col-sm-6">
												<label for="csubject" className="sr-only">Subject</label>
												<input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" id="csubject" placeholder="Subject" />
											</div>
										</div>

										<label for="cmessage" className="sr-only">Message</label>
										<textarea className="form-control" value={msg} onChange={(e) => setMsg(e.target.value)} cols="30" rows="4" id="cmessage" required placeholder="Message *"></textarea>

										<button onClick={addMsg} className="btn btn-outline-primary-2 btn-minwidth-sm">
											<span>SUBMIT</span>
											<i className="icon-long-arrow-right"></i>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
				<Footer />
			</div>
		</>
	)
}

export default Contact
