import React, { useState } from "react";
import Navbar from "./inc/Navbar";
import Footer from "./inc/Footer";
import Carousel from "react-bootstrap/Carousel";
import { NavLink, Link } from "react-router-dom";
import brand1 from "./images/brands/1.PNG";
import brand2 from "./images/brands/2.PNG";
import brand3 from "./images/brands/3.PNG";
import brand4 from "./images/brands/4.PNG";
import brand5 from "./images/brands/6.PNG";
import brand7 from "./images/brands/7.PNG";

const Home = () => {
  const [trPrList, setTrPrList] = useState([]);

  const product = async () => {
    let trprres = await fetch(window.api + "trendyProducts");
    trprres = await trprres.json();
    setTrPrList(trprres);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    product();
  });

  return (
    <>
      <div className="page-wrapper">
        <Navbar />
        <main className="main">
          <div className="intro-section bg-lighter pt-1 pb-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="intro-inner">
                    <h1>Dawn Analytics</h1>
                    <p>
                      We would like to introduce ourselves as the multi
                      functional house of laboratory equipment and calibration
                      services provider.
                    </p>
                    <NavLink to="/about" className="about-btn">
                      About
                    </NavLink>
                  </div>
                </div>
                <div className="col-lg-6">
                  <Carousel fade>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="assets/images/slider/slider1.png"
                        alt="First slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="assets/images/slider/slider2.png"
                        alt="Second slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="assets/images/slider/slider3.png"
                        alt="Third slide"
                      />
                    </Carousel.Item>
                  </Carousel>
                </div>
              </div>
              <div className="row our-client">
                <div className="trendy">
                  <h1>Trendy Products</h1>
                  <div className="row">
                    {Object.keys(trPrList).length > 0 ? (
                      trPrList.map((v) => (
                        <div className="col-sm-3 text-center">
                          <Link to={"/Product/" + v.id}>
                            <div className="product">
                              <figure className="product-media">
                                <a href="product.html">
                                  <img
                                    src={window.storage + v.image}
                                    alt="Product image"
                                    className="product-image"
                                  />
                                  <img
                                    src={window.storage + v.image}
                                    alt="Product image"
                                    className="product-image-hover"
                                  />
                                </a>
                              </figure>
                              <div className="product-body">
                                <h3 className="product-title">{v.name}</h3>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="more-container text-center">
                    <NavLink
                      to="/Allproduct"
                      className="btn btn-outline-darker btn-more"
                    >
                      <span>View All products</span>
                      <i className="icon-long-arrow-right"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-6"></div>

          <div className="container trendy1">
            <div className="heading heading-center mb-3">
              <h2 className="title-lg">Our Principles</h2>
            </div>
            <div className="row">
              <div className="col-sm-2">
                <a href="#" className="brand">
                  <img src={brand1} alt="Brand Name" />
                </a>
              </div>
              <div className="col-sm-2">
                <a href="#" className="brand">
                  <img src={brand2} alt="Brand Name" />
                </a>
              </div>
              <div className="col-sm-2">
                <a href="#" className="brand">
                  <img src={brand3} alt="Brand Name" />
                </a>
              </div>
              <div className="col-sm-2">
                <a href="#" className="brand">
                  <img src={brand4} alt="Brand Name" />
                </a>
              </div>
              <div className="col-sm-2">
                <a href="#" className="brand">
                  <img src={brand5} alt="Brand Name" />
                </a>
              </div>
              <div className="col-sm-2">
                <a href="#" className="brand">
                  <img src={brand7} alt="Brand Name" />
                </a>
              </div>
            </div>
          </div>

          <div className="services">
            <div className="container ">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6">
                  <div className="icon-box icon-box-card text-center">
                    <span className="icon-box-icon">
                      <i className="icon-rocket"></i>
                    </span>
                    <div className="icon-box-content">
                      <h3 className="icon-box-title">Payment & Delivery</h3>
                      <p>Free shipping for orders over $50</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6">
                  <div className="icon-box icon-box-card text-center">
                    <span className="icon-box-icon">
                      <i className="icon-rotate-left"></i>
                    </span>
                    <div className="icon-box-content">
                      <h3 className="icon-box-title">Return & Refund</h3>
                      <p>Free 100% money back guarantee</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6">
                  <div className="icon-box icon-box-card text-center">
                    <span className="icon-box-icon">
                      <i className="icon-life-ring"></i>
                    </span>
                    <div className="icon-box-content">
                      <h3 className="icon-box-title">Quality Support</h3>
                      <p>Alway online feedback 24/7</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-2"></div>
            </div>
          </div>
          {/* <div className="cta cta-display bg-image pt-4 pb-4" style={{ backgroundColor: '#777777' }}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-10 col-lg-9 col-xl-8">
                                    <div className="row no-gutters flex-column flex-sm-row align-items-sm-center">
                                        <div className="col">
                                            <h3 className="cta-title text-white">Sign Up </h3>
                                            <p className="cta-desc text-white">Dawn presents the best in interior design</p>
                                        </div>

                                        <div className="col-auto">
                                            <a href="login.html" className="btn btn-outline-white"><span>SIGN UP</span><i className="icon-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Home;
