import React, { useState } from "react";
import Navbar from "./inc/Navbar";
import Footer from "./inc/Footer";
import Sidenav from "./inc/Sidenav";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [oneCertificateList, setOneCertificateList] = useState([]);
  const userId = window.sessionStorage.getItem("id");

  const getCertificates = async () => {
    let cerRes = await fetch(window.api + "manualCertificate/" + userId);
    cerRes = await cerRes.json();
    setOneCertificateList(cerRes.certificate);
    console.warn(cerRes.certificate);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getCertificates();
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        <Navbar />
        <main className="main">
          <br />
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-sm-10">
                  <center>All certificates</center>
                  <table
                    class="table table-striped table-hover"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Department</th>
                        <th>Title</th>
                        <th>Certificate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(oneCertificateList).length > 0 ? (
                        oneCertificateList.map((c) => (
                          <tr>
                            <td>{c.id}</td>
                            <td>{c.dept_name}</td>
                            <td>{c.title}</td>
                            <td>
                              {c.cer_file != null ? (
                                <a
                                  href={
                                    window.main +
                                    "openCertificate/" +
                                    c.cer_file
                                  }
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  View
                                </a>
                              ) : (
                                <a></a>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="col-sm-2">
                  <Sidenav />
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
