import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const Sidenav = () => {
    return (
        <>
            <div className='row' style={{ border: '1px solid #EBEBEB' }}>
                <div className='col-12'>
                    <center>
                        <h6>Menu</h6>
                    </center>
                </div>
                <div className='col-12'>
                    <ul>
                        <li>
                            <NavLink to="/Dashboard">
                                <span className='floar-left'>Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/OldCertificates">
                                <span className='floar-left'>Old Certificates</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/Logout">
                                <span className='floar-left'>Logout</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidenav
