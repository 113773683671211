import React, { useState, } from 'react'
import Navbar from './inc/Navbar'
import Footer from './inc/Footer'
import "./css/card.css"

const Team = () => {
    const [teamData, setTeamData] = useState([])

    const Team = async () => {
        let teamres = await fetch(window.api + "teamList")
        teamres = await teamres.json()
        setTeamData(teamres)
      };
    
      React.useEffect(() => {
        window.scrollTo(0, 0);
        Team();
      }, []);

    return (
        <>
            <div className="page-wrapper">
                <Navbar />
                <main className="main">
                    <br />
                    <div className="page-content">
                        <div className="container">
                            <div className='row'>
                                {
                                    (teamData != "") ?
                                        <>
                                            {
                                                teamData.map(t => (
                                                    <div className='col-sm-2'>
                                                        <img src={window.storage + t.image} style={{ borderRadius: '50px', height: '200px', width: '100%' }} />
                                                        <center>
                                                            <p class="card-text"><b>{t.name}</b><br />({t.designation})</p>
                                                        </center>
                                                    </div>
                                                ))
                                            }
                                        </> :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </>
    )
}

export default Team
