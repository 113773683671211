import { Route, Routes } from "react-router-dom"
import Home from "./components/Home"
import Product from "./components/Product"
import Event from "./components/Event"
import Contact from "./components/Contact"
import About from "./components/About"
import Login from "./components/Login"
import Dashboard from "./components/Dashboard"
import ViewCertificate from "./components/ViewCertificate"
import Logout from "./components/Logout"
import Team from "./components/Team"
import OldCertificates from "./components/OldCertificates"
import Allproduct from "./components/Allproduct"
import Manuals from "./components/Manuals"

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/Product/:p1" element={<Product />} />
        <Route path="/Event" element={<Event />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/About" element={<About />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/ViewCertificate/:p1" element={<ViewCertificate />} />
        <Route path="/Logout" element={<Logout />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Allproduct" element={<Allproduct />}/>
        <Route path="/OldCertificates" element={<OldCertificates />}/>
        <Route path="/Manuals" element={<Manuals />}/>
      </Routes>
    </>
  );
}

export default App;
