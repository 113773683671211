import React, { useState } from "react";
import Navbar from "./inc/Navbar";
import Footer from "./inc/Footer";
import Sidenav from "./inc/Sidenav";
import { Link } from "react-router-dom";

const Manuals = () => {
    const [manualList, setManualList] = useState([]);
    const userId = window.sessionStorage.getItem("id");
  
    const getManuals = async () => {
      let cerRes = await fetch(window.api + "manualList");
      cerRes = await cerRes.json();
      setManualList(cerRes);
    };
  
    React.useEffect(() => {
      window.scrollTo(0, 0);
      getManuals();
    }, []);
  return (
    <>
      <div className="page-wrapper">
        <Navbar />
        <main className="main">
          <br />
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <center>All Manuals</center>
                  <table
                    class="table table-sm table-striped table-hover"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        {/* <th>#</th> */}
                        <th>Title</th>
                        <th>File</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(manualList).length > 0 ? (
                        manualList.map((c) => (
                          <tr>
                            {/* <td>{c.id}</td> */}
                            <td>{c.title}</td>
                            <td>
                              {c.file != null ? (
                                <a
                                  href={
                                    window.main +
                                    "downloadManual/" +
                                    c.file
                                  }
                                  download={c.file}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  Download
                                </a>
                              ) : (
                                <a></a>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Manuals
