import React, { useState, useEffect, useRef } from 'react'
import Navbar from './inc/Navbar'
import Footer from './inc/Footer'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AcUnitIcon from '@mui/icons-material/AcUnit'
import { useParams, Link } from "react-router-dom"
import parse from "html-react-parser"

const Product = () => {
    const params = useParams()
    const proId = params.p1

    const [productData, setProductData] = useState("")
    const [imgSrc, setImgSrc] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [comments, setComments] = useState("")
    const [commentsData, setCommentsData] = useState([])
    const [success, setSuccess] = useState(false)
    const [productList, setProductList] = useState([])
    const [pro, setPro] = useState(proId)

    async function product() {
        let productres = await fetch(window.api + "product/" + pro)
        productres = await productres.json()
        setProductData(productres.product)
        setCommentsData(productres.comments)
        setProductList(productres.products)
        setImgSrc(productres.product.image)
    }

    useEffect(async () => {
        window.scrollTo(0, 0);
        product()
    }, [])


    async function addComments() {
        // setloader(true)
        const formData = new FormData()
        formData.append('pro_id', productData.id)
        formData.append('name', name)
        formData.append('email', email)
        formData.append('comments', comments)

        let result = await fetch(window.api + "addComments", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result.success) {
            setSuccess(true)
            // setloader(false)
            setName("")
            setEmail("")
            setComments("")
            product()
        }
    }

    async function getProduct(proId) {
        setPro(proId)
        let newprores = await fetch(window.api + "product/" + proId)
        newprores = await newprores.json()
        setProductData(newprores.product)
        setCommentsData(newprores.comments)
    }
    const imageClick = (img) => {
        setImgSrc(img)
    }

    // const myJSON = JSON.stringify(productData.description);

    const string  = '' + productData.description
    return (
        <>
            <div className="page-wrapper">
                <Navbar />
                <main className="main">

                    <br /><br />
                    <div className="page-content">
                        <div className="container">

                            <div className="row">
                                <div className="col-lg-12">
                                    {
                                        (productData !== null) ?
                                            <>
                                                <article className="entry single-entry">

                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <figure className="entry-media">
                                                                <img src={window.storage + imgSrc} alt="image desc" style={{ height: '350px' }} />
                                                            </figure>
                                                            <div className='media-gallery'>
                                                                <img src={window.storage + productData.image} alt='img' onClick={() => imageClick(productData.image)} />
                                                                <img src={window.storage + productData.image1} alt='img' onClick={() => imageClick(productData.image1)} />
                                                                <img src={window.storage + productData.image2} alt='img' onClick={() => imageClick(productData.image2)} />
                                                            </div>
                                                            <div className="entry-meta">
                                                                {/* <span className="entry-author">
                                                                    by <a href="#">Dawn Analytical</a>
                                                                </span>
                                                                <span className="meta-separator">|</span> */}
                                                                <a href="#">{(productData != "") ? productData.created_at.substr(0, 10) : <></>}</a>
                                                                <span className="meta-separator">|</span>
                                                                <a href="#">{commentsData.length} Comments</a>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <div className="entry-body">
                                                                <h2 className="entry-title">
                                                                    {productData.name}<br />
                                                                </h2>
                                                                <div className="entry-cats">
                                                                    Model: <a href="#">{productData.model}</a>,
                                                                </div>
                                                                <div className="entry-content editor-content">
                                                                    <p style={{ textAlign: 'justify' }}>
                                                                     {
                                                                         
                                                                        parse(string)
                                                                                
                                                                        }
                                                                    </p>
                                                                    <div className="pb-1"></div>
                                                                    <div className="pb-1 clearfix"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                                <div className="comments">
                                                    <h3 className="title">{commentsData.length} Comments</h3>
                                                    <ul>
                                                        {Object.keys(commentsData).length > 0 ? commentsData.map((b, index) => (
                                                            <li key={index}>
                                                                <div className="comment">
                                                                    <div className="comment-body">
                                                                        <div className="comment-user">
                                                                            <h4><a href="#">{b.name}</a></h4>
                                                                            <span className="comment-date">{(b.created_at != "") ? b.created_at.substr(0, 10) : <></>}</span>
                                                                        </div>
                                                                        <div className="comment-content">
                                                                            <p>{b.comment}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )) :
                                                            <>
                                                            </>}
                                                    </ul>
                                                </div>
                                                <div className="reply">
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <h3 className="title">Leave A Reply</h3>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            {success
                                                                ? <div className="alert alert-success" role="alert"> Comment Saved Successfully!</div>
                                                                : <div></div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Name" />
                                                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email" />
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <textarea value={comments} onChange={(e) => setComments(e.target.value)} className="form-control" placeholder="comment"></textarea>
                                                        </div>
                                                        <div className="col-lg-1">
                                                            <button onClick={addComments} style={{ marginTop: '40px', color: 'whitesmoke' }} className="btn-primary comment-reply">Send</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> :
                                            <>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <center>
                                                            <h3>Product is unavailable</h3>
                                                        </center>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </div>

                            </div>
                            <div className="row mt-5" >
                                <div className="col-md-12">
                                    <div className='row'>
                                        <div className='col'>
                                            <center>
                                                <h3>Recommended for you

                                                </h3>
                                            </center>
                                        </div>
                                    </div>
                                    <div className="row mt-1 grid">
                                        {Object.keys(productList).length > 0 ? productList.map(p => (
                                            <div className="col-md-3">
                                                <Link to={"/Product/" + p.id}>
                                                    <div className='product'>
                                                        <figure className="product-media">
                                                            <img src={window.storage + p.image} alt="Product image" className="product-image" />
                                                            <img src={window.storage + p.image} alt="Product image" className="product-image-hover" />
                                                        </figure>
                                                        <div className="product-body">
                                                            <h3 className="product-title">{p.name}</h3>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )) : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>

        </>
    )
}



export default Product
