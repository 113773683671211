import React from 'react'
import { NavLink, Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div>
            <footer className="footer ">
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-lg-3">
                                <div className="widget widget-about">
                                    <img src="assets/images/logo.png" className="footer-logo" alt="Footer Logo" width="105" height="25" />
                                    <p>DAWN ANALYTICAL SUPPLIES & CALIBRATORS</p>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title">Useful Links</h4>

                                    <ul className="widget-list">
                                        <li><NavLink to="/About">About Us</NavLink></li>
                                        {/* <li><a href="#">FAQ</a></li> */}
                                        <li><NavLink to="/Contact">Contact us</NavLink></li>
                                        {/* <li><a href="login.html">Log in</a></li> */}
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-6 col-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title">Customer Service</h4>
                                    <ul className="widget-list">
                                        <li><a href="#">Terms and conditions</a></li>
                                        <li><a href="#">Privacy Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title">My Account</h4>
                                    <ul className="widget-list">
                                        <li><a href="#">Sign In</a></li>
                                        <li><a href="#">My Wishlist</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <p className="footer-copyright">Copyright © 2021. All Rights Reserved. Developed By: <a href="https://micrologicx.net/">MicrologicX</a></p>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Footer
