import React, { useState } from "react";
import Navbar from "./inc/Navbar";
import Footer from "./inc/Footer";
import Sidenav from "./inc/Sidenav";
import { Link } from "react-router-dom";

const OldCertificates = () => {
  const [oneCertificateList, setOneCertificateList] = useState([]);
  const userId = window.sessionStorage.getItem("id");

  const getCertificates = async () => {
    let cerRes = await fetch(window.api + "certificate/" + userId);
    cerRes = await cerRes.json();
    setOneCertificateList(cerRes.oneCertificate);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getCertificates();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <Navbar />
        <main className="main">
          <br />
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-sm-10">
                  <center>All certificates</center>
                  <table
                    class="table table-striped table-hover"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>C-Certificate</th>
                        <th>Equipment</th>
                        <th>Model</th>
                        <th>Certificate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(oneCertificateList).length > 0 ? (
                        oneCertificateList.map((c) => (
                          <tr>
                            <td>{c.id}</td>
                            <td>{c.c_certificate}</td>
                            <td>{c.equipment}</td>
                            <td>{c.model}</td>
                            <td>
                              <Link
                                to={"/ViewCertificate/" + c.id}
                                style={{ color: "blue" }}
                                target="_blank"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="col-sm-2">
                  <Sidenav />
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default OldCertificates;
