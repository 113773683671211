import React from 'react'
import Navbar from './inc/Navbar'
import Footer from './inc/Footer'
import about from "./images/page-header-bg.jpg"

const About = () => {
    return (
        <>
            <div className="page-wrapper">
                <Navbar />
                <main className="main">
                    <div className="page-header text-center" style={{ backgroundImage: `url(${about})` }}>
                        <div className="container">
                            <h1 className="page-title">About Us </h1>
                        </div>
                    </div>
                    <div className="page-content pb-3">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="about-text text-center mt-3">
                                        <h2 className="title text-center mb-2">Who We Are</h2>
                                        <p>We would like to introduce ourselves as the multi functional house of laboratory equipment and calibration services provider. From the modest beginning in 1999, we have succeeded to become substantial oriented house with unique spectrum of capabilities.
                                            We are successful because of continuous improvement in products & also customer’s feed back. We want you to experience the high quality Lab equipments and services from the house of Dawn. With dedicated work force and efforts we continue to excel in doing our best to always serve you better every time.</p>
                                        {/* <img src="assets/images/about/about-2/signature.png" alt="signature" className="mx-auto mb-5" />

                                        <img src="assets/images/about/about-2/img-1.jpg" alt="image" className="mx-auto mb-6" /> */}
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="icon-box icon-box-sm text-center">
                                        <span className="icon-box-icon">
                                            <i className="icon-puzzle-piece"></i>
                                        </span>
                                        <div className="icon-box-content">
                                            <h3 className="icon-box-title">Design Quality</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6">
                                    <div className="icon-box icon-box-sm text-center">
                                        <span className="icon-box-icon">
                                            <i className="icon-life-ring"></i>
                                        </span>
                                        <div className="icon-box-content">
                                            <h3 className="icon-box-title">Professional Support</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-sm-6">
                                    <div className="icon-box icon-box-sm text-center">
                                        <span className="icon-box-icon">
                                            <i className="icon-heart-o"></i>
                                        </span>
                                        <div className="icon-box-content">
                                            <h3 className="icon-box-title">Made With Love</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="mb-2"></div>


                        {/* <div className="container">
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="brands-text text-center mx-auto mb-6">
                                        <h2 className="title">The world's premium design brands in one destination.</h2>

                                    </div>
                                    <div className="brands-display">
                                        <div className="row justify-content-center">
                                            <div className="col-6 col-sm-4 col-md-3">
                                                <a href="#" className="brand">
                                                    <img src="assets/images/brands/1.png" alt="Brand Name" />
                                                </a>
                                            </div>

                                            <div className="col-6 col-sm-4 col-md-3">
                                                <a href="#" className="brand">
                                                    <img src="assets/images/brands/2.png" alt="Brand Name" />
                                                </a>
                                            </div>

                                            <div className="col-6 col-sm-4 col-md-3">
                                                <a href="#" className="brand">
                                                    <img src="assets/images/brands/3.png" alt="Brand Name" />
                                                </a>
                                            </div>

                                            <div className="col-6 col-sm-4 col-md-3">
                                                <a href="#" className="brand">
                                                    <img src="assets/images/brands/7.png" alt="Brand Name" />
                                                </a>
                                            </div>

                                            <div className="col-6 col-sm-4 col-md-3">
                                                <a href="#" className="brand">
                                                    <img src="assets/images/brands/4.png" alt="Brand Name" />
                                                </a>
                                            </div>

                                            <div className="col-6 col-sm-4 col-md-3">
                                                <a href="#" className="brand">
                                                    <img src="assets/images/brands/5.png" alt="Brand Name" />
                                                </a>
                                            </div>

                                            <div className="col-6 col-sm-4 col-md-3">
                                                <a href="#" className="brand">
                                                    <img src="assets/images/brands/6.png" alt="Brand Name" />
                                                </a>
                                            </div>

                                            <div className="col-6 col-sm-4 col-md-3">
                                                <a href="#" className="brand">
                                                    <img src="assets/images/brands/9.png" alt="Brand Name" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </main>
                <Footer />
            </div>
        </>
    )
}

export default About
