import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import "../css/style.css"


const Navbar = () => {

    // const [productList, setProductList] = useState([])
    // const [eventList, setEventList] = useState([])

    // useEffect(async () => {
    //     window.scrollTo(0, 0)

    //     let list = await fetch(window.api + "NavList")
    //     list = await list.json()
    //     setProductList(list.products)
    //     setEventList(list.events)

    // }, [])




    return (
        <>
            <header className="header">
                <div className="header-top">
                    <div className="container-fluid">
                        <div className="header-right">
                            <ul className="top-menu">
                                <li>
                                    <ul>
                                        <li><span>Tel:</span> +92 42 35171076</li>
                                        <li><span>Mobile:</span> 0334-9814152</li>
                                        <li><span>Fax:</span> +92 42 35171075</li>
                                        {/* <li><a href="wishlist.html"><i className="icon-heart-o"></i>My Wishlist <span>(3)</span></a></li>
                                        <li><a href="about.html">About Us</a></li>
                                        <li><a href="contact.html">Contact Us</a></li>
                                        <li><a href="#signin-modal" data-toggle="modal"><i className="icon-user"></i>Login</a></li> */}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="header-middle sticky-header">
                    <div className="container">
                        <div className="header-left">
                            <button className="mobile-menu-toggler">
                                <span className="sr-only">Toggle mobile menu</span>
                                <i className="icon-bars"></i>
                            </button>
                            <NavLink to="/">
                                <img src="assets/images/logo.png" alt="Molla Logo" width="80px" />
                            </NavLink>
                            <nav className="main-nav">
                                <ul className="menu sf-arrows">
                                    <li className="megamenu-container" activeclassname='active'>
                                        <NavLink to="/">
                                            <a>Home</a>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <Link to="/Allproduct" activeclassname='active'>All Products </Link>
                                    </li>
                                    {/* 
                                    <li>
                                        <NavLink to="/Product" activeclassname='active'>
                                            <a>Products</a>
                                        </NavLink>
                                        <a className="sf-with-ul">Products</a>
                                        <ul className="megamenu-sm" style={{ width: '280px' }}>
                                            {
                                                productList.map(v => (
                                                    <li>
                                                        <a className="sf-with-ul">{v.main.name}</a>
                                                        <ul>
                                                            {
                                                                v.sub.map(s => (
                                                                    <Link onClick={onLinkClick} to={"/Product/" + v.main.id + "/" + s.id}>
                                                                        <li><a>{s.name}</a></li>
                                                                    </Link>
                                                                ))
                                                            }
                                                        </ul>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </li> 
                                    */}
                                    <li>
                                        <NavLink to="/Event" activeclassname='active'>
                                            <a>Events</a>
                                        </NavLink>
                                        {/* <a className="sf-with-ul">Events</a> */}
                                        {/* <ul style={{ width: '100%' }}>
                                            {
                                                eventList.map(e => (
                                                    <li>
                                                        <Link to={"/Event/" + e.id}>
                                                            {e.name}
                                                        </Link>
                                                    </li>
                                                ))
                                            }
                                        </ul> */}
                                    </li>
                                    <li>
                                        <Link to="/Team" activeclassname='active'>Team</Link>
                                    </li>
                                    <li>
                                        <Link to="/About" activeclassname='active'>About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/Manuals" activeclassname='active'>Instruction Manuals</Link>
                                    </li>
                                    <li>
                                        <Link to="/Contact" activeclassname='active'>Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/Login" activeclassname='active'>Customer Portal </Link>
                                    </li>

                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

        </>
    )
}

export default Navbar
