import React, { useState, useEffect, useRef } from "react";
import Navbar from "./inc/Navbar";
import Footer from "./inc/Footer";
import { useParams } from "react-router-dom";
import "./css/style.css";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import "@progress/kendo-theme-default/dist/all.css";
import DownloadIcon from "@mui/icons-material/Download";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import sign1 from "./images/sign1.png";
import sign2 from "./images/sign2.png";
import sign3 from "./images/sign3.png";
import stamp1 from "./images/stamp1.png";
import stamp2 from "./images/stamp2.png";
import { useReactToPrint } from "react-to-print";

const ViewCertificate = () => {
  const image = React.useRef(null);

  const container = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  const [cerData, setCerData] = useState("");
  const [userData, setUserData] = useState("");
  const [cerDataPhParameter, setCerDataPhParameter] = useState("");
  const [cerDataWaveParameter, setCerDataWaveParameter] = useState("");
  const [certwoValueone, setCertwoValueone] = useState("");
  const [flowRate, setFlowRate] = useState("");
  const [noiseParameter, setNoiseParameter] = useState("");
  const [volumeParameter, setVolumeParameter] = useState("");
  const [parameter, setParameter] = useState("");
  const [ltParameter, setLtParameter] = useState("");
  const [ltParameter1, setLtParameter1] = useState("");
  const [mmParameter, setMmParameter] = useState("");
  const [mgParameter, setMgParameter] = useState("");
  const [tParameter, setTParameter] = useState("");
  const [tcParameter, setTcParameter] = useState("");
  const [asParameter, setAsParameter] = useState("");
  const [ftParameter, setFtParameter] = useState("");
  const [pgParameter, setPgParameter] = useState("");
  const [huParameter, setHuParameter] = useState("");
  const [teParameter, setTeParameter] = useState("");
  const [cmParameter, setCmParameter] = useState("");
  const [abParameter, setAbParameter] = useState("");
  const [ab1Parameter, setAb1Parameter] = useState("");
  const [kfParameter, setKfParameter] = useState("");
  const [pcParameter, setPcParameter] = useState("");
  const [amParameter, setAmParameter] = useState("");
  const [acpParameter, setAcpParameter] = useState("");
  const [actParameter, setActParameter] = useState("");
  const [maParameter, setMaParameter] = useState("");
  const [ma1Parameter, setMa1Parameter] = useState("");
  const [phmParameter, setPhmParameter] = useState("");
  const [phm1Parameter, setPhm1Parameter] = useState("");
  const [cm1Parameter, setCm1Parameter] = useState("");
  const [dwbParameter, setDwbParameter] = useState("");
  const [dwb1Parameter, setDwb1Parameter] = useState("");
  const [dwbmParameter, setDwbmParameter] = useState("");
  const [dtParameter, setDtParameter] = useState("");
  const [dt1Parameter, setDt1Parameter] = useState("");
  const [ftaParameter, setFtaParameter] = useState("");
  const [fta1Parameter, setFta1Parameter] = useState("");
  const [lfcParameter, setLfcParameter] = useState("");
  const [lfc1Parameter, setLfc1Parameter] = useState("");
  const [pParameter, setPParameter] = useState("");
  const [rmParameter, setRmParameter] = useState("");
  const [tmParameter, setTmParameter] = useState("");
  const [wbParameter, setWbParameter] = useState("");
  const [otherParameter, setOtherParameter] = useState("");
  const [acOtherParameter, setAcOtherParameter] = useState("");
  const [dsParameter, setDsParameter] = useState("");
  const [ds1Parameter, setDs1Parameter] = useState("");
  const [ds2Parameter, setDs2Parameter] = useState("");
  const [hmParameter, setHmParameter] = useState("");
  const [hm1Parameter, setHm1Parameter] = useState("");
  const [pmParameter, setPmParameter] = useState("");

  const params = useParams();
  const cerId = params.p1;

  const getCertificateData = async () => {
    let cerRes = await fetch(window.api + "certificateData/" + cerId);
    cerRes = await cerRes.json();
    setCerData(cerRes.certificate);
    setUserData(cerRes.user);
    setCerDataPhParameter(cerRes.ph_parameter);
    setCerDataWaveParameter(cerRes.wave_parameter);
    setCertwoValueone(cerRes.cer2Value1);
    setFlowRate(cerRes.flow_rate);
    setNoiseParameter(cerRes.noise_parameter);
    setVolumeParameter(cerRes.volume_parameter);
    setParameter(cerRes.parameter);
    setLtParameter(cerRes.lt_parameter);
    setLtParameter1(cerRes.lt_parameter1);
    setMmParameter(cerRes.mm_parameter);
    setMgParameter(cerRes.mg_parameter);
    setTParameter(cerRes.t_parameter);
    setTcParameter(cerRes.tc_parameter);
    setAsParameter(cerRes.as_parameter);
    setFtParameter(cerRes.ft_parameter);
    setPgParameter(cerRes.pg_parameter);
    setHuParameter(cerRes.hu_Parameter);
    setTeParameter(cerRes.te_Parameter);
    setCmParameter(cerRes.cm_parameter);
    setAbParameter(cerRes.ab_parameter);
    setAb1Parameter(cerRes.ab1_parameter);
    setKfParameter(cerRes.kf_parameter);
    setPcParameter(cerRes.pc_parameter);
    setAmParameter(cerRes.am_Parameters);
    setAcpParameter(cerRes.acp_parameters);
    setActParameter(cerRes.act_parameters);
    setMaParameter(cerRes.ma_parameters);
    setMa1Parameter(cerRes.ma1_parameters);
    setPhmParameter(cerRes.phm_parameters);
    setPhm1Parameter(cerRes.phm1_parameters);
    setCm1Parameter(cerRes.cm1_parameters);
    setDwbParameter(cerRes.dwb_Parameters);
    setDwb1Parameter(cerRes.dwb1_Parameters);
    setDwbmParameter(cerRes.dwbm_Parameters);
    setDtParameter(cerRes.dt_Parameters);
    setDt1Parameter(cerRes.dt1_Parameters);
    setFtaParameter(cerRes.fta_Parameters);
    setFta1Parameter(cerRes.fta1_Parameters);
    setLfcParameter(cerRes.lfc_Parameters);
    setLfc1Parameter(cerRes.lfc1_Parameters);
    setPParameter(cerRes.p_Parameters);
    setRmParameter(cerRes.rm_Parameters);
    setTmParameter(cerRes.tm_Parameters);
    setWbParameter(cerRes.wb_Parameters);
    setOtherParameter(cerRes.other_Parameters);
    setAcOtherParameter(cerRes.ac_other_parameters);
    setDsParameter(cerRes.ds_Parameters);
    setDs1Parameter(cerRes.ds1_Parameters);
    setDs2Parameter(cerRes.ds2_Parameters);
    setHmParameter(cerRes.hm_Parameters);
    setHm1Parameter(cerRes.hm1_Parameters);
    setPmParameter(cerRes.pm_Parameters);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getCertificateData();
  }, []);

  const exportPDFWithMethod = () => {
    let element = container.current || document.body;
    let getImage = image.current || document.body;
    savePDF(element, {
      paperSize: "auto",
      margin: 10,
      fileName: `${
        cerData.c_certificate
      } Certificate ${cerData.created_at.substr(0, 10)}`,
    });
  };

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => container.current,
  });

  return (
    <>
      <div className="page-wrapper">
        <Navbar />
        <main className="main">
          <br />
          <div className="page-content">
            <div className="container">
              {cerData === "" ? (
                <>
                  <center>
                    <i>Please wait while loading....</i>
                  </center>
                </>
              ) : (
                <div>
                  <PDFExport
                    ref={pdfExportComponent}
                    paperSize="A4"
                    margin={10}
                    fileName={`Certificate`}
                    author="KendoReact Team"
                  >
                    <div className="row">
                      <div className="col-sm-10">
                        <div class="wrapper" ref={container}>
                          <div class="cHeader">
                            <div class="logo">
                              {cerData.logo === null ? (
                                <>
                                  <img
                                    src="assets/images/logo.png"
                                    alt=""
                                    width="80px"
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={window.storage + cerData.logo}
                                    alt=""
                                    width="80px"
                                  />
                                </>
                              )}
                            </div>
                            <div class="companyName">
                              <h1>Dawn Calibration Lab</h1>
                              <p>Calibration Certificate</p>
                            </div>
                            {/* {
                                                                (cerData.cer_id == 10 || cerData.cer_id == 14 || cerData.cer_id == 21 || cerData.cer_id == 9 || cerData.cer_id == 7 || cerData.cer_id == 37 || cerData.cer_id == 38) ?
                                                                    <>
                                                                        <div class="logo1">
                                                                            <img src="assets/images/logo2.png" alt="" />
                                                                        </div>
                                                                    </> :
                                                                    <></>
                                                            } */}
                          </div>
                          <div className="myTable">
                            <table className="half">
                              <tr>
                                <td>
                                  <b>Certificate #:</b> {cerData.c_certificate}
                                </td>
                                <td>
                                  <b>Dawn Data Sheet No.:</b>{" "}
                                  {cerData.dawn_data_sheet}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Equipment:</b> {cerData.equipment}
                                </td>
                                <td>
                                  <b>Section:</b> {cerData.section}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Model/Type:</b> {cerData.model}
                                </td>
                                <td>
                                  <b>
                                    {cerData.temp_label === null ? (
                                      "Laboratory "
                                    ) : (
                                      <>
                                        {cerData.temp_label == "L"
                                          ? "Laboratory "
                                          : "Room "}
                                      </>
                                    )}
                                    Temperature:
                                  </b>{" "}
                                  {cerData.lab_temp}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Instrument ID:</b>
                                  {cerData.instr_id}
                                </td>
                                <td>
                                  <b>
                                    {cerData.hum_label === null ? (
                                      "Laboratory "
                                    ) : (
                                      <>
                                        {cerData.hum_label == "L"
                                          ? "Laboratory "
                                          : "Room "}
                                      </>
                                    )}
                                    Humidity:
                                  </b>{" "}
                                  {cerData.lab_humidity}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>Manufacturer:</b> {cerData.manufacturer}
                                </td>
                                <td>
                                  <b>Calibrated Date:</b> {cerData.calib_date}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Client:</strong> {userData.name} (
                                  {userData.address})
                                </td>
                                <td>
                                  <b>Next Calibration Date:</b>{" "}
                                  {cerData.next_calib}
                                </td>
                              </tr>
                            </table>
                          </div>

                          {cerDataPhParameter === null ? (
                            <></>
                          ) : (
                            <>
                              <h5>1. Photometric Accuracy:</h5>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>

                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>Wavelength λmax nm</th>
                                    <th colspan="2">
                                      Observed Value Absorbance
                                    </th>
                                    <th>Tolerance ±0.01</th>
                                  </tr>
                                  <tr>
                                    <th></th>
                                    <th>Applied Value</th>
                                    <th>Observed Value</th>
                                  </tr>
                                  {Object.keys(cerDataPhParameter).length >
                                  0 ? (
                                    cerDataPhParameter.map((v) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{v.waveLength}</td>
                                        <td>{v.appliedValue}</td>
                                        <td>{v.observedValue}</td>
                                        <td>{v.tolerance}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          )}
                          {cerDataWaveParameter !== null ? (
                            <>
                              <h5>2. Wavelength:</h5>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>Wave Length(nm)</th>
                                    <th>Observed λmax nm</th>
                                    <th>Tolerance</th>
                                    <th>Deviation / Error Unit: nm </th>
                                  </tr>
                                  {Object.keys(cerDataWaveParameter).length >
                                  0 ? (
                                    cerDataWaveParameter.map((w) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{w.waveLengthW}</td>
                                        <td>{w.observedW}</td>
                                        <td>{w.toleranceW}</td>
                                        <td>{w.deviationW}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {noiseParameter !== null ? (
                            <>
                              <h5>3. Noise:</h5>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th colSpan="2">
                                      <center>at 250 & 550nm</center>
                                    </th>
                                  </tr>
                                  <tr>
                                    <th>Result</th>
                                    <th>Tolerance</th>
                                  </tr>
                                  {Object.keys(noiseParameter).length > 0 ? (
                                    noiseParameter.map((n) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{n.results}</td>
                                        <td>{n.tolerence}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <br />
                          {certwoValueone !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                    </th>
                                    <th>Deviation / Error</th>
                                  </tr>
                                  {Object.keys(certwoValueone).length > 0 ? (
                                    certwoValueone.map((v) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{v.appliedValue}</td>
                                        <td>{v.observedValue}</td>
                                        <td>{v.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {flowRate !== null ? (
                            <>
                              <h6>FLOW RATE</h6>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>Values on the Reference Equipment</th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                    </th>
                                    <th>Deviation / Error</th>
                                  </tr>
                                  {Object.keys(flowRate).length > 0 ? (
                                    flowRate.map((f) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{f.appliedValue}</td>
                                        <td>{f.observedValue}</td>
                                        <td>{f.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {volumeParameter !== null ? (
                            <>
                              <h6>Volume:</h6>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>Injection Volume</th>
                                    <th>Peak Area</th>
                                    <th>Results</th>
                                  </tr>
                                  {Object.keys(volumeParameter).length > 0 ? (
                                    volumeParameter.map((v) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{v.volume}</td>
                                        <td>{v.tolerance}</td>
                                        <td>{v.results}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                              <ul>
                                <li>Five replicate injections</li>
                                <li>
                                  Relative Standard Deviation{" "}
                                  {cerData.relative_standard_deviation}
                                </li>
                              </ul>
                            </>
                          ) : (
                            <></>
                          )}
                          {parameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>Particle Size micron</th>
                                    <th>Applied value Of *CRSS</th>
                                    <th>Observed value</th>
                                    <th>Deviation / Error</th>
                                  </tr>
                                  {Object.keys(parameter).length > 0 ? (
                                    parameter.map((p) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{p.pSize}</td>
                                        <td>{p.appliedValue}</td>
                                        <td>{p.observedValue}</td>
                                        <td>{p.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                              <ul>
                                <li
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  *Certified Reference Standard Solution
                                </li>
                                <li
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  **Average Value of Three Readings
                                </li>
                              </ul>
                            </>
                          ) : (
                            <></>
                          )}
                          {ltParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                    <th>
                                      Expanded Uncertainty
                                      <br />
                                      ±({cerData.unit})
                                    </th>
                                  </tr>

                                  {Object.keys(ltParameter).length > 0 ? (
                                    ltParameter.map((l) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{l.appliedValue}</td>
                                        <td>{l.observedValue}</td>
                                        <td>{l.deviation}</td>
                                        <td>{l.uncertainty}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {ltParameter1 !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range1}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit1}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit1}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit1}</th>
                                    <th>
                                      Expanded Uncertainty
                                      <br />
                                      ±({cerData.unit1})
                                    </th>
                                  </tr>

                                  {Object.keys(ltParameter1).length > 0 ? (
                                    ltParameter1.map((l) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{l.appliedValue1}</td>
                                        <td>{l.observedValue1}</td>
                                        <td>{l.deviation1}</td>
                                        <td>{l.uncertainty1}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {mmParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(mmParameter).length > 0 ? (
                                    mmParameter.map((m) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{m.appliedValue}</td>
                                        <td>{m.observedValue}</td>
                                        <td>{m.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {mgParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                    <th>
                                      Expanded Uncertainty
                                      <br />
                                      ±({cerData.unit})
                                    </th>
                                  </tr>
                                  {Object.keys(mgParameter).length > 0 ? (
                                    mgParameter.map((m) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{m.appliedValue}</td>
                                        <td>{m.observedValue}</td>
                                        <td>{m.deviation}</td>
                                        <td>{m.uncertainty}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {tParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>

                              <div class="myTable">
                                <span
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  Timer{" "}
                                  <span style={{ fontSize: "25px" }}>*</span>
                                </span>
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                    <th>
                                      Expanded Uncertainty
                                      <br />
                                      ±({cerData.unit})
                                    </th>
                                  </tr>
                                  {Object.keys(tParameter).length > 0 ? (
                                    tParameter.map((t) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{t.appliedValue}</td>
                                        <td>{t.observedValue}</td>
                                        <td>{t.deviation}</td>
                                        <td>{t.uncertainty}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {tcParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(tcParameter).length > 0 ? (
                                    tcParameter.map((t) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{t.appliedValue}</td>
                                        <td>{t.observedValue}</td>
                                        <td>{t.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {asParameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(asParameter).length > 0 ? (
                                    asParameter.map((a) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{a.appliedValue}</td>
                                        <td>{a.observedValue}</td>
                                        <td>{a.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {ftParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                  
                                    <th>Applied Value</th>
                                    <th>Observed Value</th>
                                    <th>Deviation / Error</th>
                                  </tr>
                                  {Object.keys(ftParameter).length > 0 ? (
                                    ftParameter.map((f) => (
                                      <tr style={{ textAlign: "center" }}>
                                     
                                        <td>{f.appliedValue}</td>
                                        <td>{f.observedValue}</td>
                                        <td>{f.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {pgParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                              </div>
                              <div class="myTable">
                                <span
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  Pressure{" "}
                                  <span style={{ fontSize: "25px" }}>*</span>
                                </span>
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                    <th>
                                      Expanded Uncertainty
                                      <br />
                                      ±({cerData.unit})
                                    </th>
                                  </tr>
                                  {Object.keys(pgParameter).length > 0 ? (
                                    pgParameter.map((p) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{p.appliedValue}</td>
                                        <td>{p.observedValue}</td>
                                        <td>{p.deviation}</td>
                                        <td>{p.uncertainty}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {huParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Humidity:
                                  </strong>{" "}
                                  {cerData.humidity}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(huParameter).length > 0 ? (
                                    huParameter.map((h) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{h.appliedValue}</td>
                                        <td>{h.observedValue}</td>
                                        <td>{h.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {teParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Temperature :
                                  </strong>{" "}
                                  {cerData.temperature}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit1}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit1}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit1}</th>
                                  </tr>
                                  {Object.keys(teParameter).length > 0 ? (
                                    teParameter.map((t) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{t.appliedValue}</td>
                                        <td>{t.observedValue}</td>
                                        <td>{t.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {cmParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(cmParameter).length > 0 ? (
                                    cmParameter.map((c) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{c.appliedValue}</td>
                                        <td>{c.observedValue}</td>
                                        <td>{c.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {abParameter !== null && ab1Parameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(abParameter).length > 0 ? (
                                    abParameter.map((ab) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{ab.appliedValue}</td>
                                        <td>{ab.observedValue}</td>
                                        <td>{ab.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                  <tr>
                                    <th>{cerData.unit1}</th>
                                    <th>{cerData.unit1}</th>
                                    <th>{cerData.unit1}</th>
                                  </tr>
                                  {Object.keys(ab1Parameter).length > 0 ? (
                                    ab1Parameter.map((ab1) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{ab1.appliedValue}</td>
                                        <td>{ab1.observedValue}</td>
                                        <td>{ab1.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {kfParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Cal. No.
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(kfParameter).length > 0 ? (
                                    kfParameter.map((k) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{k.appliedValue}</td>
                                        <td>{k.observedValue}</td>
                                        <td>{k.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {pcParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  Air borne particle counter in operation
                                  condition
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Particle size
                                      <br /> {cerData.unit}
                                    </th>
                                    <th>
                                      Observed Value
                                      <br /> {cerData.unit} in rest condition
                                    </th>
                                    <th>
                                      Observed Value
                                      <br /> {cerData.unit} in operation
                                      condition
                                    </th>
                                    <th>
                                      Deviation / Error
                                      <br /> {cerData.unit}
                                    </th>
                                  </tr>
                                  {Object.keys(pcParameter).length > 0 ? (
                                    pcParameter.map((p) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{p.partSize}</td>
                                        <td>{p.restCondition}</td>
                                        <td>{p.operCondition}</td>
                                        <td>{p.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {amParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(amParameter).length > 0 ? (
                                    amParameter.map((am) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{am.appliedValue}</td>
                                        <td>{am.observedValue}</td>
                                        <td>{am.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {acpParameter !== null ? (
                            <>
                              <span
                                style={{ fontWeight: "400", color: "black" }}
                              >
                                * Pressure
                              </span>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>

                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment <br />{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration <br />{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>
                                      Deviation / Error <br /> {cerData.unit}
                                    </th>
                                    <th>
                                      Expanded Uncertainty
                                      <br />
                                      ±({cerData.unit})
                                    </th>
                                  </tr>
                                  {Object.keys(acpParameter).length > 0 ? (
                                    acpParameter.map((acp) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{acp.appliedValue}</td>
                                        <td>{acp.observedValue}</td>
                                        <td>{acp.deviation}</td>
                                        <td>{acp.uncertainty}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {actParameter !== null ? (
                            <>
                              <span
                                style={{ fontWeight: "400", color: "black" }}
                              >
                                Temperature
                              </span>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment <br />{" "}
                                      {cerData.unit1}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration <br />{" "}
                                      {cerData.unit1}{" "}
                                    </th>
                                    <th>
                                      Deviation / Error <br /> {cerData.unit1}
                                    </th>
                                  </tr>
                                  {Object.keys(actParameter).length > 0 ? (
                                    actParameter.map((act) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{act.appliedValue}</td>
                                        <td>{act.observedValue}</td>
                                        <td>{act.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {maParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(maParameter).length > 0 ? (
                                    maParameter.map((ma) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{ma.appliedValue}</td>
                                        <td>{ma.observedValue}</td>
                                        <td>{ma.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {ma1Parameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range1}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit1}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit1}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit1}</th>
                                  </tr>
                                  {Object.keys(ma1Parameter).length > 0 ? (
                                    ma1Parameter.map((ma1) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{ma1.appliedValue}</td>
                                        <td>{ma1.observedValue}</td>
                                        <td>{ma1.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {phmParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(phmParameter).length > 0 ? (
                                    phmParameter.map((phm) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{phm.appliedValue}</td>
                                        <td>{phm.observedValue}</td>
                                        <td>{phm.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {phm1Parameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit1}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit1}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit1}</th>
                                  </tr>
                                  {Object.keys(phm1Parameter).length > 0 ? (
                                    phm1Parameter.map((phm1) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{phm1.appliedValue}</td>
                                        <td>{phm1.observedValue}</td>
                                        <td>{phm1.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {cm1Parameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(cm1Parameter).length > 0 ? (
                                    cm1Parameter.map((cm1) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{cm1.appliedValue}</td>
                                        <td>{cm1.observedValue}</td>
                                        <td>{cm1.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {dwbParameter !== null && dwb1Parameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(dwbParameter).length > 0 ? (
                                    dwbParameter.map((dwb) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{dwb.appliedValue}</td>
                                        <td>{dwb.observedValue}</td>
                                        <td>{dwb.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit1}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit1}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit1}</th>
                                  </tr>
                                  {Object.keys(dwb1Parameter).length > 0 ? (
                                    dwb1Parameter.map((dwb1) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{dwb1.appliedValue}</td>
                                        <td>{dwb1.observedValue}</td>
                                        <td>{dwb1.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {dwbmParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(dwbmParameter).length > 0 ? (
                                    dwbmParameter.map((dwbm) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{dwbm.appliedValue}</td>
                                        <td>{dwbm.observedValue}</td>
                                        <td>{dwbm.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {dtParameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(dtParameter).length > 0 ? (
                                    dtParameter.map((dt) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{dt.appliedValue}</td>
                                        <td>{dt.observedValue}</td>
                                        <td>{dt.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {dt1Parameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit1}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit1}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit1}</th>
                                  </tr>
                                  {Object.keys(dt1Parameter).length > 0 ? (
                                    dt1Parameter.map((dt1) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{dt1.appliedValue}</td>
                                        <td>{dt1.observedValue}</td>
                                        <td>{dt1.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {ftaParameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(ftaParameter).length > 0 ? (
                                    ftaParameter.map((fta) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{fta.appliedValue}</td>
                                        <td>{fta.observedValue}</td>
                                        <td>{fta.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          <br />
                          {fta1Parameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>Applied value {cerData.unit1}</th>
                                    <th>observed value {cerData.unit1} </th>
                                    <th>Deviation / Error {cerData.unit1}</th>
                                    <th>uncertainty {cerData.unit1}</th>
                                  </tr>
                                  {Object.keys(fta1Parameter).length > 0 ? (
                                    fta1Parameter.map((fta1) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{fta1.appliedValue}</td>
                                        <td>{fta1.observedValue}</td>
                                        <td>{fta1.deviation}</td>
                                        <td>{fta1.uncertainty}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {lfcParameter !== null && lfc1Parameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>Values on the Reference Equipment </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                    </th>
                                    <th>Deviation / Error </th>
                                  </tr>
                                  <tr>
                                    <th colSpan={2}>Air Born Particles</th>
                                  </tr>
                                  {Object.keys(lfcParameter).length > 0 ? (
                                    lfcParameter.map((lfc) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{lfc.appliedValue}</td>
                                        <td>{lfc.observedValue}</td>
                                        <td>{lfc.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                  <tr>
                                    <th colSpan={2}>Air Velocity</th>
                                  </tr>
                                  {Object.keys(lfc1Parameter).length > 0 ? (
                                    lfc1Parameter.map((lfc1) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{lfc1.appliedValue}</td>
                                        <td>{lfc1.observedValue}</td>
                                        <td>{lfc1.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* {
                                                            (cerData.c_certificate === 'MRL/PM-7715') ?
                                                                <>
                                                                    <div class="result">
                                                                        <p style={{ fontWeight: "400", color: "black" }}><strong style={{ color: "#666" }}>Range:</strong> {cerData.ph_range}</p>
                                                                        <p style={{ fontWeight: "400", color: "black" }}><strong style={{ color: "#666" }}>Resolution:</strong> {cerData.ph_resolution}</p>
                                                                    </div>
                                                                </> :
                                                                <></>
                                                        } */}
                          {pParameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(pParameter).length > 0 ? (
                                    pParameter.map((p) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{p.appliedValue}</td>
                                        <td>{p.observedValue}</td>
                                        <td>{p.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {rmParameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(rmParameter).length > 0 ? (
                                    rmParameter.map((rm) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{rm.appliedValue}</td>
                                        <td>{rm.observedValue}</td>
                                        <td>{rm.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {tmParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(tmParameter).length > 0 ? (
                                    tmParameter.map((tm) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{tm.appliedValue}</td>
                                        <td>{tm.observedValue}</td>
                                        <td>{tm.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {wbParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>Deviation / Error {cerData.unit}</th>
                                  </tr>
                                  {Object.keys(wbParameter).length > 0 ? (
                                    wbParameter.map((wb) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{wb.appliedValue}</td>
                                        <td>{wb.observedValue}</td>
                                        <td>{wb.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {otherParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment
                                      <br /> {cerData.unit}
                                    </th>
                                    <th>
                                      Average Value on the Unit Under
                                      Calibration <br /> {cerData.unit}
                                    </th>
                                    <th style={{ width: "20%" }}>
                                      Error <br /> {cerData.unit}
                                    </th>
                                  </tr>
                                  {Object.keys(otherParameter).length > 0 ? (
                                    otherParameter.map((other) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{other.appliedValue}</td>
                                        <td>{other.observedValue}</td>
                                        <td>{other.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {acOtherParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment
                                      <br /> {cerData.unit}
                                    </th>
                                    <th>
                                      Average Value on the Unit Under
                                      Calibration <br /> {cerData.unit}
                                    </th>
                                    <th>
                                      Error <br /> {cerData.unit}
                                    </th>
                                    <th>
                                      Expanded Uncertainty
                                      <br />±{cerData.unit}
                                    </th>
                                  </tr>
                                  {Object.keys(acOtherParameter).length > 0 ? (
                                    acOtherParameter.map((other) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{other.appliedValue}</td>
                                        <td>{other.observedValue}</td>
                                        <td>{other.deviation}</td>
                                        <td>{other.uncertainty}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {dsParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution}
                                </p>
                              </div>
                              <span>RPM</span>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment <br />{" "}
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration <br />{" "}
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>
                                      Deviation / Error <br /> {cerData.unit}
                                    </th>
                                  </tr>
                                  {Object.keys(dsParameter).length > 0 ? (
                                    dsParameter.map((t) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{t.appliedValue}</td>
                                        <td>{t.observedValue}</td>
                                        <td>{t.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {ds1Parameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range1}
                                </p>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution1}
                                </p>
                              </div>
                              <span style={{ color: "#666" }}>Temperature</span>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment <br />{" "}
                                      {cerData.unit1}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration <br />{" "}
                                      {cerData.unit1}{" "}
                                    </th>
                                    <th>
                                      Deviation / Error <br /> {cerData.unit1}
                                    </th>
                                  </tr>
                                  {Object.keys(ds1Parameter).length > 0 ? (
                                    ds1Parameter.map((t) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{t.appliedValue}</td>1
                                        <td>{t.observedValue}</td>
                                        <td>{t.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {ds2Parameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Timer (Min){" "}
                                    <span style={{ fontSize: "25px" }}>*</span>
                                  </strong>
                                </p>

                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Resolution:
                                  </strong>{" "}
                                  {cerData.ph_resolution2}
                                </p>
                              </div>

                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Apllied Value
                                      <br /> {cerData.unit2}
                                    </th>
                                    <th>
                                      Observed Value
                                      <br /> {cerData.unit2}
                                    </th>
                                    <th>
                                      Deviation / Error <br /> {cerData.unit2}
                                    </th>
                                    <th>
                                      Expanded Uncertainty
                                      <br />
                                      ±({cerData.unit2})
                                    </th>
                                  </tr>
                                  {Object.keys(ds2Parameter).length > 0 ? (
                                    ds2Parameter.map((t) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{t.appliedValue}</td>
                                        <td>{t.observedValue}</td>
                                        <td>{t.deviation}</td>
                                        <td>{t.uncertainty}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                              <ul>
                                <li>
                                  All manufacturing specifications calibrated as
                                  per USP requirement.
                                </li>
                              </ul>
                            </>
                          ) : (
                            <></>
                          )}
                          {hmParameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment <br />
                                      {cerData.unit}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration <br />
                                      {cerData.unit}{" "}
                                    </th>
                                    <th>
                                      Deviation / Error <br />
                                      {cerData.unit}
                                    </th>
                                  </tr>
                                  {Object.keys(hmParameter).length > 0 ? (
                                    hmParameter.map((h) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{h.appliedValue}</td>
                                        <td>{h.observedValue}</td>
                                        <td>{h.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {hm1Parameter !== null ? (
                            <>
                              <div class="result">
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  <strong style={{ color: "#666" }}>
                                    Range:
                                  </strong>{" "}
                                  {cerData.ph_range1}
                                </p>
                              </div>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>
                                      Values on the Reference Equipment <br />
                                      {cerData.unit1}
                                    </th>
                                    <th>
                                      Value on the Unit Under Calibration <br />
                                      {cerData.unit1}{" "}
                                    </th>
                                    <th>
                                      Deviation / Error <br />
                                      {cerData.unit1}
                                    </th>
                                  </tr>
                                  {Object.keys(hm1Parameter).length > 0 ? (
                                    hm1Parameter.map((h) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{h.appliedValue}</td>
                                        <td>{h.observedValue}</td>
                                        <td>{h.deviation}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                          {pmParameter !== null ? (
                            <>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>Concentration of sucrose</th>
                                    <th>Optical Rotations</th>
                                    <th>Observed Value</th>
                                    <th>Deviation / Error</th>
                                  </tr>
                                  {Object.keys(pmParameter).length > 0 ? (
                                    pmParameter.map((p) => (
                                      <tr style={{ textAlign: "center" }}>
                                        <td>{p.appliedValue}</td>
                                        <td>{p.observedValue}</td>
                                        <td>{p.deviation}</td>
                                        <td>{p.uncertainty}</td>
                                      </tr>
                                    ))
                                  ) : (
                                    <></>
                                  )}
                                </table>
                              </div>
                              <p>
                                <b>*Certified Reference Standard</b>
                              </p>
                              <p>
                                <b>**Average Value of Three Readings</b>
                              </p>
                            </>
                          ) : (
                            <></>
                          )}
                          {cerData.cer_id == 10 ||
                          cerData.cer_id == 14 ||
                          cerData.cer_id == 21 ||
                          cerData.cer_id == 9 ||
                          cerData.cer_id == 7 ||
                          cerData.cer_id == 37 ||
                          cerData.cer_id == 38 ? (
                            <>
                              <center>
                                <p
                                  style={{ fontWeight: "400", color: "black" }}
                                >
                                  [Uncertainty (<i>U</i>) with coverge factor K
                                  = 2 and level of confidence: 95%]
                                </p>
                              </center>
                            </>
                          ) : (
                            <></>
                          )}
                          {/* {
                                                            (cerData.cer_id == 31) ?
                                                                <>
                                                                    <div class="result">
                                                                        <p style={{ fontWeight: "400", color: "black" }}><strong style={{ color: "#666" }}>Range:</strong> {cerData.ph_range}</p>
                                                                        <p style={{ fontWeight: "400", color: "black" }}><strong style={{ color: "#666" }}>Resolution:</strong> {cerData.ph_resolution}</p>
                                                                    </div>
                                                                </> :
                                                                <></>
                                                        } */}
                          <br />
                          {cerData.stray_light === null ? (
                            <></>
                          ) : (
                            <>
                              <h5>3. Limit of Stray light: </h5>
                              <p style={{ fontWeight: "400", color: "black" }}>
                                {cerData.stray_light}
                              </p>
                            </>
                          )}
                          {cerData.resolution === null ? (
                            <></>
                          ) : (
                            <>
                              <h5>4. Resolution: </h5>
                              <p style={{ fontWeight: "400", color: "black" }}>
                                {cerData.resolution}
                              </p>
                            </>
                          )}
                          {cerData.cell === null ? (
                            <></>
                          ) : (
                            <>
                              <h5>5. Cell: </h5>
                              <p style={{ fontWeight: "400", color: "black" }}>
                                {cerData.cell}
                              </p>
                            </>
                          )}
                          {cerData.leak_ob === null ? (
                            <></>
                          ) : (
                            <>
                              <h5>
                                6. Performance qualification of UV-VIS
                                Detector.:
                              </h5>
                              <div class="myTable">
                                <table className="half-other">
                                  <tr>
                                    <th>Parameter</th>
                                    <th>Observation</th>
                                    <th>Acceptance Criteria</th>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Leak test</b>
                                    </td>
                                    <td>{cerData.leak_ob}</td>
                                    <td>{cerData.leak_cri}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Wavelength accuracy</b>
                                    </td>
                                    <td>{cerData.ac_ob}</td>
                                    <td>{cerData.ac_cri}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Wavelength reproducibility</b>
                                    </td>
                                    <td>{cerData.rep_ob}</td>
                                    <td>{cerData.rep_cri}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Photometric Accuracy</b>
                                    </td>
                                    <td>{cerData.ph_ob}</td>
                                    <td>{cerData.ph_cri}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Limit of Stray light</b>
                                    </td>
                                    <td>{cerData.st_ob}</td>
                                    <td>{cerData.st_cri}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Resolution</b>
                                    </td>
                                    <td>{cerData.res_ob}</td>
                                    <td>{cerData.res_cri}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Cell </b>
                                    </td>
                                    <td>{cerData.cell_ob}</td>
                                    <td>{cerData.cell_cri}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Comments</b>
                                    </td>
                                    <td>{cerData.com_ob}</td>
                                  </tr>
                                </table>
                              </div>
                            </>
                          )}
                          {cerData.performance_testing === null ? (
                            <></>
                          ) : (
                            <div class="measure">
                              <h1>Performance Testing:</h1>
                              <p>{cerData.performance_testing}</p>
                            </div>
                          )}
                          {cerData.observations === null ? (
                            <></>
                          ) : (
                            <div class="measure">
                              <h1>Performance Testing:</h1>
                              <p>{cerData.observations}</p>
                            </div>
                          )}

                          {cerData.spectra !== null ? (
                            <>
                              <center>
                                {/* <img src={window.storage + cerData.spectra} /> */}
                                <img
                                  src={window.storage + cerData.spectra}
                                  width="40%"
                                />
                              </center>
                            </>
                          ) : (
                            <></>
                          )}

                          {cerData.method_used1 === null ? (
                            <></>
                          ) : (
                            <div class="measure">
                              <p>
                                <h1 style={{ display: "inline" }}>
                                  Method used:
                                </h1>{" "}
                                {cerData.method_used1}
                              </p>
                            </div>
                          )}

                          {cerData.trace_measure === null ? (
                            <></>
                          ) : (
                            <div class="measure">
                              <h1>Traceability of measurements:</h1>
                              <p>{cerData.trace_measure}</p>
                            </div>
                          )}
                          {cerData.method_used === null ? (
                            <></>
                          ) : (
                            <div class="measure">
                              <h1>Performance Testing:</h1>
                              <p>{cerData.method_used}</p>
                            </div>
                          )}
                          {/* {
                                                            (tParameter !== null || pgParameter !== null || dsParameter !== null || ds1Parameter !== null || ds2Parameter !== null || acpParameter !== null) ?
                                                                <>
                                                                    <span style={{ color: 'red' }}>(*) is accredited parameter.</span>
                                                                </> :
                                                                <></>
                                                        } */}
                          <br />

                          <div class="myTable">
                            <table>
                              <tr style={{ textAlign: "center" }}>
                                <td>
                                  <b>CALIBRATED BY</b>
                                </td>
                                <td>
                                  <b>REVIEWED BY</b>
                                </td>
                                <td>
                                  <b>APPROVED BY</b>
                                </td>
                              </tr>
                              <tr
                                class="signature"
                                style={{ textAlign: "center" }}
                              >
                                <td className="stamp1">
                                  <center>
                                    <img src={sign2} width="25%" />
                                  </center>
                                </td>
                                <td>
                                  <center>
                                    <img src={sign1} width="25%" />
                                  </center>
                                </td>
                                <td className="stamp2">
                                  <center>
                                    <img src={sign3} width="25%" />
                                  </center>
                                </td>
                              </tr>
                              <tr style={{ textAlign: "center" }}>
                                <td>
                                  <b>
                                    {cerData.operator_name === null ? (
                                      "Operator "
                                    ) : (
                                      <>{cerData.operator_name}</>
                                    )}
                                  </b>
                                </td>
                                <td>
                                  <b>Dy. Technical Manager</b>
                                </td>
                                <td>
                                  <b>Technical Manager</b>
                                </td>
                              </tr>
                            </table>
                            <p
                              class="issueDate"
                              style={{ fontWeight: "400", color: "black" }}
                            >
                              Issue Date:{" "}
                              {cerData === "" ? (
                                <></>
                              ) : (
                                <>
                                  {
                                    // cerData.created_at.substr(0, 10)
                                    cerData.issue_date
                                  }
                                </>
                              )}
                            </p>
                          </div>
                          <div class="cFooter">
                            <p style={{ color: "#123A8A" }}>
                              <b>
                                75-M Model Town Ext., Lahore, Pakistan Tel:
                                +92-42-35171076 <br />
                                E-mail: info@dawnanalyticals.com,
                                dawnanalyticals@gmail.com Web:
                                www.dawnanalytic.com
                              </b>
                            </p>
                            <h5 className="text-center">
                              ..........................................................End..........................................................
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div class="card">
                          <div class="card-header">You would like to</div>
                          <ul class="list-group list-group-flush">
                            {/* <li class="list-group-item">
                                                                <i onClick={exportPDFWithMethod}><DownloadIcon style={{ fontSize: '20px', color: '#123A8A' }} titleAccess='Download as PDF' /> Download as PDF</i>
                                                            </li> */}
                            <li class="list-group-item">
                              <i onClick={handlePrint}>
                                <LocalPrintshopIcon
                                  style={{ fontSize: "20px", color: "#123A8A" }}
                                  titleAccess="Print it"
                                />{" "}
                                Print this out!
                              </i>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </PDFExport>
                </div>
              )}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ViewCertificate;
