import React, { useState, useEffect, useRef } from 'react'
import Navbar from './inc/Navbar'
import Footer from './inc/Footer'
import contact from "./images/contact-header-bg.jpg"
import { useNavigate } from 'react-router-dom'


const Login = () => {

    const navigate = useNavigate()
    const [buttonText, setButtonText] = useState("LOG IN")
    const [errorText, setErrorText] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    useEffect(()=>{
        if(window.sessionStorage.getItem('id')){
            navigate("/Dashboard")
        }
    },[])

    async function LogIn() {
        setButtonText("Loging In")
        const formData = new FormData()
        formData.append('email', email)
        formData.append('password', password)

        let result = await fetch(window.api + "logIn", {
            method: 'POST',
            body: formData
        });
        result = await result.json()
        if (result.errorEmail) {
            setError(true)
            setErrorText("Email does not exist")
            setButtonText("LOG IN")
        }else if (result.errorPassword) {
            setError(true)
            setErrorText("Password does not match")
            setButtonText("LOG IN")
        }else {
            setError(false)
            window.sessionStorage.setItem("id", result.id)
            setButtonText("LOG IN")
            navigate('/Dashboard')
        }
    }

    return (
        <div>
            <div className="page-wrapper">
                <Navbar />
                <main className="main">
                    <div class="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17" style={{ backgroundImage: `url(${contact})` }}>
                        <div class="container">
                            <div class="form-box">
                                <div class="form-tab">
                                    <ul class="nav nav-pills nav-fill" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="signin-tab-2" data-toggle="tab" href="#signin-2" role="tab" aria-controls="signin-2" aria-selected="true">Sign In</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="signin-2" role="tabpanel" aria-labelledby="signin-tab-2">
                                            {error ?
                                                <div className="alert alert-danger alert-dismissible">
                                                    {/* <button type="button" className="close" data-dismiss="alert" aria-hidden="true">&times;</button> */}
                                                    {errorText}
                                                </div> :
                                                <></>
                                            }
                                            <div class="form-group">
                                                <label for="singin-email-2">Email address *</label>
                                                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} class="form-control" />
                                            </div>
                                            <div class="form-group">
                                                <label for="singin-password-2">Password *</label>
                                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} class="form-control" />
                                            </div>
                                            <div class="form-footer">
                                                <button type="submit" onClick={LogIn} class="btn btn-outline-primary-2">
                                                    <span>{buttonText}</span>
                                                    <i class="icon-long-arrow-right"></i>
                                                </button>
                                                {/* <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" id="signin-remember-2" />
                                                        <label class="custom-control-label" for="signin-remember-2">Remember Me</label>
                                                    </div> */}

                                                {/* <a href="#" class="forgot-link">Forgot Your Password?</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    )
}

export default Login
