import React, { useState, useEffect, useRef } from "react";
import Navbar from "./inc/Navbar";
import Footer from "./inc/Footer";
import { useParams } from "react-router-dom";

const Event = () => {
  const [eventData, setEventData] = useState([]);
  const params = useParams();

  const Event = async () => {
    let eventres = await fetch(window.api + "EventData/" + params.p1);
    eventres = await eventres.json();
    setEventData(eventres);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    Event();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <Navbar />
        <main className="main">
          <br />
          <div className="page-content">
            <div className="container">
              {eventData != "" ? (
                <>
                  {eventData.map((b) => (
                    <div className="row">
                      <div className="col-12">
                        <div className="banner banner-big">
                          <img
                            src="assets/images/banners/banner-fullwidth.jpg"
                            alt="Banner"
                          />
                          <div className="banner-content">
                            <h4 className="banner-subtitle text-primary">
                              Quisque a lectus
                            </h4>
                            <h3 className="banner-title text-white">
                              Morbi interdum <br />
                              mollis sapien.
                            </h3>
                            <p className="d-none d-lg-block">
                              Lorem ipsum dolor sit amet, consectetuer
                              adipiscing elit. Donec odio. Quisque volutpat
                              mattis eros. Nullam malesuada erat ut turpis.
                              Suspendisse urna nibh, viverra non, posuere a,
                              pede.
                            </p>

                            <a href="#" className="btn btn-primary btn-rounded">
                              <span>Click Here</span>
                              <i className="icon-long-arrow-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <div className="row">No Data</div>
                </>
              )}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Event;
